<ngx-spinner></ngx-spinner>
<mat-drawer-container id="container" autosize>
  <mat-drawer #sidenav mode="side" disableClose="true" opened="true" style="top:48px" *ngIf="isAdmin || isUBI || isImpersonating || isFED || isRBI || isNPM">
    <app-sidebar id="home_4" *ngIf="!isAdmin"></app-sidebar>
    <mint-admin-sidebar *ngIf="isAdmin"></mint-admin-sidebar>
  </mat-drawer>

  <mat-drawer-content style="margin-left: unset;">
    <div id="home_2">
      <app-header id="home_3" *ngIf="isAdmin || isUBI || isImpersonating || isFED || isRBI || isNPM" (toggleClickEvent)="toggleEvent($event)" [title]="title"></app-header>
    </div>
    <main id="mainContent" tabindex="-1">
      <div class="notifications__container">
        <app-optout-notification id="home_5" *ngIf="!sharedContext.isOptOutBannerClosed && (isUBI || isImpersonating || isFED || isRBI || isNPM)" class="optout"
          [isBanner]="true" (closeEvent)="closeOptOutBannerEvent($event)"></app-optout-notification>
        <mint-banner-notification *ngIf="!isAdmin && (isUBI || isImpersonating || isFED || isRBI || isNPM)"></mint-banner-notification>
        <app-rollover-notification id="home_8" *ngIf="isRolloverNotify()  && (isUBI || isImpersonating || isFED || isRBI || isNPM)" class="impersonation">
        </app-rollover-notification>
        <app-complaince-notification id="home_9" class="impersonation" *ngIf="isUBI || isImpersonating || isFED || isRBI || isNPM">
        </app-complaince-notification>
        <app-demo-notification id="home_10" *ngIf="sharedContext.isDemoMode2" class="impersonation">
        </app-demo-notification>
        <!-- <notification-bars id="home_11" class="impersonation"></notification-bars> -->
      </div>
      <div id="allContent" class="all__content dashboard__bg" style="padding-right: 0px" *ngIf="isAdmin || isUBI || isImpersonating || isFED || isRBI || isNPM; else SunsetMessage">
        <router-outlet id="home_12" (activate)="changeOfRoutes()"></router-outlet>
      </div>
      <ng-template #SunsetMessage>
        <deprecation-sunset>
        </deprecation-sunset>
      </ng-template>
    </main>
  </mat-drawer-content>

</mat-drawer-container>

<!-- <ng-template id="mic2_splash_1" #mic2SplashTemplate let-modal>
  <mint-mic2-splash id="mic2_splash_2" [modalInstance]="modalInstance"></mint-mic2-splash>
</ng-template> -->