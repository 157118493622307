<div id="container">
  <div class="sunset-title">
    <span>
      Oops! Mint is retired. 
      This page no longer exists. Please navigate to the new incentive compensation experience, 
      <a href="https://aka.ms/msxearnings">MSX Earnings</a>.
    </span>
    <br />
    <br />
    <span>
      Questions? Review the Mint to MSX Earnings transition pages on the Incentive Compensation Guide
    </span>
    <br />
    <ul>
      <li>
        <span>RBI: </span><a href="https://aka.ms/MSXEarnings-Landing-RBI">https://aka.ms/MSXEarnings-Landing-RBI</a>
      </li>
      <li>
        <span>Sales leaders: </span><a href="https://aka.ms/MSXEarnings-Landing-SalesLeaders">https://aka.ms/MSXEarnings-Landing-SalesLeaders</a>
      </li>
    </ul>
  </div>
</div>