import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTourService } from '../shared/training/pagetour.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { SidebarComponent } from '../layout/sidebar/user-sidebar/sidebar.component';
import { AdminSidebarComponent } from '../layout/sidebar/admin-sidebar/admin-sidebar.component';
import { Title } from '@angular/platform-browser';
import { UserProfileState, LoadUserProfile, ResetUserProfile } from '@mint-libs/context';
import { Store } from '@ngrx/store';
import { ConfigurationService } from '@mint-libs/configuration';
import { BaseContainerComponent } from './base-container.component';
import { SidebarService } from '../layout/sidebar/user-sidebar/sidebar.service';
import { LocalStorageService } from '@mint-libs/network';
import { UserProfileService } from '../core/context/user-profile.service';
import { ImpersonationService } from '../core/impersonation/impersonation.service';
import { selectSharedContextState, SharedContext, UpdateSharedContext } from '@mint-libs/common';
import { AuthenticationService } from '@mint-libs/authentication';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [PageTourService]
})
export class HomeComponent extends BaseContainerComponent implements OnInit {
  filterConfig: any;
  view = null;
  isOptOutEligibleFlag = false;
  title: string;
  isRBI: boolean;
  isUBI: boolean;
  isNPM: boolean;
  isFED: boolean;
  isImpersonating: boolean;
  @ViewChild(SidebarComponent)
  private sidebarComponent: SidebarComponent;
  @ViewChild(AdminSidebarComponent)
  private adminSidebarComponent: AdminSidebarComponent;

  // mic2 splash variables
  mic2SplashPopupKey = 'mic2SplashPopUp';
  @ViewChild('mic2SplashTemplate') mic2SplashTemplate: TemplateRef<any>;
  modalInstance;
  isAdmin = false;
  public sharedContext: SharedContext = new SharedContext();

  constructor(
    private impersonationService: ImpersonationService,
    public sharedContextState: Store<SharedContext>,
    public userProfileService: UserProfileService,
    private localStorageService: LocalStorageService,
    protected activeRoute: ActivatedRoute,
    private route: Router,
    private modalService: NgbModal,
    private titleService: Title,
    protected userProfileState: Store<UserProfileState>,
    private authService: AuthenticationService,
    private configuration: ConfigurationService,
    public sidebarService: SidebarService
  ) {
    super(sidebarService, activeRoute);

    route.events.subscribe(() => {
      this.isAdmin = this.isAdminSideBar();
    });

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.checkIncentiveType();
    });
  }

  ngOnInit() {
    this.changeOfRoutes();
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        isOptOutBannerClosed: false
      })
    );
    this.isOptOutEligibleFlag = this.isOptOutEligible();
    this.isFED = window.location.host.includes('microsoftfederal');

    if (this.route.url === '/demo') {
      this.sharedContextState.dispatch(new UpdateSharedContext({ ...this.sharedContext, isDemoMode3: true }));
    }

    this.isImpersonating = this.impersonationService.isImpersonating();
    this.isAdmin = this.isAdminSideBar();

    if (!this.authService.isAuthenticated()) {
    } else {
      this.userProfileState.dispatch(new ResetUserProfile());
      this.userProfileState.dispatch(new LoadUserProfile(null));
      this.configuration.init();
    }
    // this.openMic2SplashIfApplicable();
  }

  isAdminSideBar(): boolean {
    return this.route.url.startsWith('/admin');
  }

  checkIncentiveType() {
    this.isRBI = this.isIncentiveTypeActive('RBI');
    this.isUBI = this.isIncentiveTypeActive('UBI');
    this.isNPM = this.sharedContext.IsNonParticipantManager;
  }

  isIncentiveTypeActive(fragment) {
    return this.sharedContext.incentiveType === fragment;
  }

  optoutDemoMode() {
    if (!this.sharedContext.isDemoMode3) {
      return true;
    }
    if (this.sharedContext.isDemoMode3 && this.sharedContext.isImpersonating === false) {
      return false;
    }
    return true;
  }

  isOptOutEligible() {
    if (this.userProfileService.isOptoutEligibleAndInWindow()) {
      return true;
    }
    return false;
  }

  isRolloverNotify() {
    if (this.sharedContext.isDemoMode3 && this.sharedContext.isImpersonating === false) {
      return false;
    }

    // Rollover message not needed for now and hence commenting
    // Removed impersonation check since admins can't see outdated messages (and hence can't act proactively)
    // if (!this.sharedContext.isRollOver) {
    //   return true;
    // }

    return false;
  }

  changeOfRoutes() {
    // this.pageTourService.checkTourExistance();
    this.activeRoute.firstChild.data.subscribe(t => {
      this.title = t['title'] ? t['title'] : '';
      this.sharedContextState.dispatch(
        new UpdateSharedContext({
          ...this.sharedContext,
          pageTitle: this.title
        })
      );
      if (this.title) {
        this.titleService.setTitle(this.title + ' | My Incentives (Mint)');
      } else {
        this.titleService.setTitle('My Incentives (Mint)');
      }
    });
  }

  toggleEvent(toggleEvent: any) {
    // if (this.isAdminSidebar) {
    //   this.adminSidebarComponent.toggleSidebar();
    // } else {
    //   this.sidebarComponent.toggleSidebar();
    // }
    super.toggleSideNav();
  }

  closeOptOutBannerEvent(closeOptOutBannerEvent: any) {
    this.sharedContextState.dispatch(
      new UpdateSharedContext({
        ...this.sharedContext,
        isOptOutBannerClosed: !this.sharedContext.isOptOutBannerClosed
      })
    );
  }

  openMic2SplashIfApplicable() {
    const key = environment.environment + this.sharedContext.alias + this.mic2SplashPopupKey;
    const mic2Profiles = this.sharedContext.StandardTitles.filter(function(title) {
      return title.isMic2Profile === true;
    });

    if (this.sharedContext.isDemoMode2 || this.localStorageService.getValueFromLocalStorage(key) || !(mic2Profiles && mic2Profiles.length > 0)) {
      return;
    }

    this.modalInstance = this.modalService.open(this.mic2SplashTemplate, {
      ariaLabelledBy: 'modal-basic-title',
      backdropClass: 'mint-modal-backdrop',
      windowClass: 'mint-modal-window',
      backdrop: 'static',
      keyboard: true
    });

    this.modalInstance.result.then(result => {
      this.localStorageService.addToLocalStorage(key, true);
    });
  }
}
