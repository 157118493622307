import { Component, OnInit, Inject, Input } from '@angular/core';
import { ComplianceService } from '../../compliance/compliance.service';
import * as Ng1Services from './../../core/hybrid/ng1-upgraded-providers';
import * as _ from 'underscore';
import { MessagesService, MeasureTypeMappingState, selectMeasureTypeMappingState, FilterState, selectFiltersState } from '@mint-libs/context';
// import { LoggerService } from '@mint-libs/logging';
import { ComplianceHelper } from '../../shared/compliance/compliance.helper';
import { Code } from '../../app.constants';
import { FiltersService } from '../../filters/filters.service';
import { Store } from '@ngrx/store';
import { NavigationService } from '../../core/navigation/navigation.service';
import { Router } from '@angular/router';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'mint-quota-detail-report',
  templateUrl: './quota-details.component.html',
  styleUrls: ['./quota-details.component.scss'],
  providers: [ComplianceService, ComplianceHelper, Code]
})
export class QuotaDetailsReportComponent implements OnInit {
  quotaDetail = null;
  notAvailable = false;
  isLoaded = false;
  showImpersonatedFCANotApprovedHTML = false;
  reportView = true;
  quotaDetailReportMessage = 'For Detailed Quota report please see Compensation Actuals by Incentive territory';
  isExportApplicable = true;
  titles = [];
  PCCs = [];
  hasQuotaMultiplier = false; /* quota multiplier*/
  complianceInfo: any;
  isRollOver: any;
  showNotAvailableMessage: boolean;
  isHalfYearlyQuotaSupported = false;
  h2QuotaBannerMessage: string;
  hasPIT = false; /*point in time*/
  pitMessage: string;
  isHalfYearlyParticipation: boolean;
  measureTypeMapping: MeasureTypeMappingState = new MeasureTypeMappingState([]);
  areQuotaDetailsFetched = false;
  isQuotaDetailDisabled = false;

  constructor(
    private reportsService: ReportsService,
    private complianceService: ComplianceService,
    private messageService: MessagesService,
    private navigationService: NavigationService,
    // private logger: LoggerService,
    private complianceHelper: ComplianceHelper,
    private filterService: FiltersService,
    private filterStore: Store<FilterState>,
    private measureState: Store<MeasureTypeMappingState>,
    private router: Router
  ) {
    this.getPCGMeasureType();
    this.filterStore.select(selectFiltersState).subscribe(filters => {
      this.isQuotaDetailDisabled = filters.is2025OrFuture;
    });
  }

  getPCGMeasureType() {
    this.measureState.select(selectMeasureTypeMappingState).subscribe(measureTypeMapping => {
      this.measureTypeMapping = measureTypeMapping;
      if (this.measureTypeMapping.isFetched) {
        this.fetchQuotaDetails();
        this.areQuotaDetailsFetched = true;
      }
    });
  }

  ngOnInit() {
    if (!this.areQuotaDetailsFetched) {
      this.fetchQuotaDetails();
    }
  }

  fetchQuotaDetails() {
    // this.logger.trackPageView('Quota detail Report');
    this.complianceHelper.getH2Message(this.reportView).subscribe(response => {
      this.h2QuotaBannerMessage = response;
    });
    this.getReportsMessage();
    this.complianceService.getQuotaDetails(this.reportView).subscribe(
      response => {
        const quotaInfo = this.complianceHelper.getQuotaDetails(response, this.isExportApplicable, this.measureTypeMapping);
        this.isLoaded = quotaInfo.isLoaded;
        this.complianceInfo = quotaInfo.complianceInfo;
        this.PCCs = quotaInfo.PCCs;
        this.hasPIT = quotaInfo.hasPIT;
        this.hasQuotaMultiplier = quotaInfo.hasQuotaMultiplier;
        this.isRollOver = quotaInfo.isRollOver;
        this.isExportApplicable = quotaInfo.isExportApplicable;
        this.notAvailable = quotaInfo.notAvailable;
        this.showNotAvailableMessage = quotaInfo.showNotAvailableMessage;
        this.showImpersonatedFCANotApprovedHTML = quotaInfo.showImpersonatedFCANotApprovedHTML;
        this.isHalfYearlyQuotaSupported = this.filterService.isH2QuotaSupported(this.filterService.selectedFiscalYear);
        this.isHalfYearlyParticipation = quotaInfo.isHalfYearlyParticipation;
        this.titles = quotaInfo.titles;
        this.pitMessage = this.complianceHelper.getPITConfigData(this.complianceInfo.QAConfigData);
      },
      () => (this.notAvailable = true),
      () => {
        this.isLoaded = true;
        if (this.notAvailable && !this.showNotAvailableMessage) {
          this.navigationService.navigateTo('/error/error', {
            message: 'An error occurred while getting the Quota details. Please try again later.'
          });
        }
      }
    ); // END Subscribe
  }

  gotoQuotaTerritoryPage() {
    this.reportsService.selectedReportIndex = 3;
    this.router.navigate(['/reports'], { queryParams: { id: '3' } });
  }

  private getReportsMessage() {
    this.messageService.get(Code.QAReportMessage).subscribe(response => {
      this.quotaDetailReportMessage = response;
    });
  }
}
