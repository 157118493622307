import { NgModule } from '@angular/core';
import { AvatarModule } from 'ngx-avatar';
import { DashboardModule } from '../dashboard/dashboard.module';
import { NotificationModule } from '../notifications/notification.module';
import { HomeComponent } from './home.component';
// import { NotificationCountDirective } from '../core/hybrid/notificationCount.directive';
// import { SupportDirective } from '../core/hybrid/support.directive';
import { SupportComponent } from '../support/support.component';
// import { CommonIssuesDirective } from '../core/hybrid/commonIssues.directive';
import { AppRoutingModule } from '../app-routing.module';
import { ReportsModule } from '../reports/reports.module';
import { SharedModule } from '../shared/shared.module';
import { FeedbackModule } from '../feedback/feedback.module';
import { CommonModule } from '@angular/common';
import { BotComponent } from '../bot/bot.component';
import { Mic2SplashComponent } from '../mic2-reports/mic2-splash/mic2-splash.component';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { SidebarService } from '../layout/sidebar/user-sidebar/sidebar.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LayoutModule } from '../layout/layout.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DeprecationSunsetComponent } from '../deprecation-sunset/deprecation-sunset.component';

// accessibility fix as default colors didn't had required contrast ratio
const avatarColors = ['#FF4D64', '#2C3E50', '#7A8F90', '#CE8209', '#17A689'];
@NgModule({
  imports: [
    AppRoutingModule,
    AvatarModule.forRoot({ colors: avatarColors }),
    DashboardModule,
    CommonModule,
    ReportsModule,
    NotificationModule,
    SharedModule,
    FeedbackModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    LayoutModule,
    NgxSpinnerModule
  ],
  declarations: [
    // NotificationCountDirective,
    // SupportDirective,
    // CommonIssuesDirective,
    Mic2SplashComponent,
    HomeComponent,
    SupportComponent,
    BotComponent,
    DisclaimersComponent,
    DeprecationSunsetComponent
  ],
  providers: [
    // {
    //   provide: '$scope',
    //   useFactory: i => i.get('$rootScope'),
    //   deps: ['$injector']
    // },
    SidebarService
  ]
})
export class HomeModule {}
