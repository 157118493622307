<div class="col-xs-12" *ngIf="isLoaded">
  <div class="m-lr-10 h2quota_banner" *ngIf="isHalfYearlyQuotaSupported && h2QuotaBannerMessage">
    <span>{{ h2QuotaBannerMessage }}</span>
  </div>
  <div id="quotadetailsbannernotification" class="row" *ngIf="!isQuotaDetailDisabled">
    <div class="col-xs-12 banner" style="display: flex; width:100%">
      <div class="icon icon-info" title="Information" id="quotadetailsbannernotification-warningicon"></div>
      <div id="quotadetailsbannernotification-text">
        <p>For Detailed Quota report please click
          <a (click)="gotoQuotaTerritoryPage()" [type]="'Click'">
            <span class="">here</span>
          </a> to see
          Quota by Incentive territory
        </p>
      </div>
    </div>
  </div>
  <div style="margin: 1rem 0"><span>This report shows your latest quota as of today.</span></div>
  <div class="col-xs-12" style="box-shadow: 0 0.3px 0.9px rgba(0,0,0,.108), 0 1.6px 3.6px rgba(0,0,0,.132);
  margin: 0px 0px 10px;">
    <mint-rbi-quota-details [PCCs]="PCCs" [titles]="titles" [isCompliancePage]="false"
      [hasQuotaMultiplier]="hasQuotaMultiplier" [isExportApplicable]="isExportApplicable" [notAvailable]="notAvailable"
      [quotaStatus]="complianceInfo?.Status" [isHalfYearlyQuotaSupported]="isHalfYearlyQuotaSupported"
      [isHalfYearlyParticipation]="isHalfYearlyParticipation" [hasPIT]="hasPIT"
      [pitInfo]="pitMessage"></mint-rbi-quota-details>
  </div>
</div>
<div *ngIf="!isLoaded" class="content__loader"><mint-content-loader-large></mint-content-loader-large></div>