import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'deprecation-sunset',
  templateUrl: './deprecation-sunset.component.html',
  styleUrls: ['./deprecation-sunset.component.scss']
})
export class DeprecationSunsetComponent implements OnInit{
  constructor() {
    // Initialization code here
  }

  ngOnInit() {
    // Logic here
    console.log('DeprecationSunsetComponent initialized');
  }
}
